<template>
  <div>
    <div>
      <p class="ml-2 mb-2">{{section}}</p>
    </div>
    <div class="bg-white shadow-sm rounded-lg p-4">
      <outbound-panel v-if="section==='Outbound'"></outbound-panel>
      <inbound-panel v-else></inbound-panel>
      <router-link :to="`/admin/document-manager`">
        <el-button class="fixed-bottom new-register" type="primary">Back Document Manager</el-button>
      </router-link>
      <el-button class="fixed-bottom new-register" style="margin-left:185px;" type="Primary" v-if="section==='Outbound'" @click="section='Inbound'">Inbound Section</el-button>
      <el-button class="fixed-bottom new-register" style="margin-left:185px;" type="Primary" v-else @click="section='Outbound'">Outbound Section</el-button>
    </div>
  </div>
</template>

<script>
import documentManager from "@/services/api/documentManager";
import OutboundPanel from "@/components/document-manager/panel/Outbound";
import InboundPanel from "@/components/document-manager/panel/Inbound";

export default {
  data() {
    return {
      section:'Inbound'
    };
  },
  components: {
    OutboundPanel,
    InboundPanel
  },
  mounted() {

  },
  methods: {
    
  }
};
</script>
